<template>
  <div>
    <div class="container">
      <div class="row d-flex justify-content-center">
        <div class="col-lg-12">
          <b-alert variant="danger" :show="true" style="font-size: 14px; margin-bottom: 2rem;">
            <h6 class="text-center">
              <strong>*** IMPORTANT NOTICE ***</strong>
            </h6>
            <p style="margin-bottom: 0.5rem;">To our valued clients,</p>
            <p style="margin-bottom: 0.5rem; text-align: justify;">Starting December 1st, 2024, Spanish Broadcasting System Inc. will be <strong>implementing a nominal surcharge of up to 3% on all credit card payments</strong>, which is not greater than our cost of acceptance.
            As a convenient alternative payment method, <strong>customers may avoid this extra fee by paying via ACH transfers directly from their bank accounts or through our new SBS payment portal once the implementation is complete</strong>.
            Please contact our support team at <a href="mailto:payments@sbscorporate.com">payments@sbscorporate.com</a> to assist you with any questions.</p>
            <p>SBS appreciates your understanding and continued partnership while ensuring we maintain delivering exceptional value within our platform of media services.</p>
          </b-alert>
        </div>
      </div>
    </div>
    <div class="login-wrapper">
      <form
        class="login-form"
        @submit="login"
      >
        <label>Welcome back</label>
        <div class="login-text">
          The SBS Payment Center facilitates your company to make electronic payments whereby you will receive a payment confirmation via email. Before you can proceed with your payments you must first register by clicking on the "Create Account" button.
        </div>
        <input
          id="account_id"
          v-model="user.email"
          type="text"
          placeholder="Email or Customer ID"
          required
        >
        <input
          id="account_password"
          v-model="user.password"
          type="password"
          placeholder="Password"
          required
        >
        <button
          type="submit"
          class="btn btn-primary"
        >
          Log In
        </button>
      </form>
      <hr>
      <router-link
        to="register"
        class="btn btn-secondary"
      >
        Create Account
      </router-link>
      <router-link
        to="forgot"
        class="btn btn-secondary"
      >
        Forgot Password
      </router-link>
    </div>
  </div>
</template>

<script>
import Axios from 'axios'

export default {
  name: 'Login',
  data () {
    return {
      user: {
        email: '',
        password: ''
      }
    }
  },
  methods: {
    login (evt) {
      evt.preventDefault()

      this.$http.post('/auth/login', { user: this.user })
        .then((res) => {
          if (!res || !res.success) {
            return this.$notify({
              group: 'notifications',
              title: 'There was a problem logging you in. Please try again :(',
              text: '',
              type: 'error'
            })
          }

          if (!res.data.user.isActive) {
            return this.$router.push({ path: '/contact', query: { auth: true } })
          }

          const user = res.data.user
          this.$analyticsManager.setUserProperties({
            UserID: user._id,
            Email: user.email,
            AccountRepresentative: user.account_representative,
            UserName: user.contact_name,
            CompanyName: user.company_name,
            CompanyType: user.company_type
          })
          this.$analyticsManager.setUserID(user._id)

          localStorage.setItem('token', res.data.token)
          localStorage.setItem('user', JSON.stringify(res.data.user))

          this.$notify({
            group: 'notifications',
            title: 'Welcome to the SBS Payment Center',
            text: '',
            type: 'success'
          })
          // Set Authorization Header globally
          Axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem('token')}`
          this.$router.push('/admin/orders')
        })
        .catch((err) => {
          console.log(err)
          this.$notify({
            group: 'notifications',
            title: err.response && err.response.status === 401 ? err.response.data.message : 'There was a problem logging you in. Please try again :(',
            text: '',
            type: 'error'
          })
        })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .login-wrapper {
    width: 100%;
    max-width: 450px;
    margin: 0 auto;
  }

  .login-wrapper label{
    color: #000;
    text-transform: uppercase;
    font-weight: 900;
    font-size: 24px;
  }

  .login-text {
    font-size: 12px;
    margin-bottom: 20px;
  }

  .login-wrapper .login-form input{
    display: block;
    width: 100%;
    padding: 0 10px;
    margin-bottom: 15px;
    border: 1px solid #eaeaea;
    height: 45px;
    font-size: 15px;
    border-radius: 0.25rem;
  }

  .login-wrapper .login-form a{
    color: #fff;
    display: block;
    padding: 10px;
    text-align: center;
    margin-bottom: 15px;
    background-color: #0B60A9;
    border-radius: 0.25rem;
  }

  .login-wrapper .login-form a:hover{
    background-color: #08487e;
    text-decoration: none;
  }

  .login-wrapper .register{
    text-align: center;
  }

  .login-wrapper .register a{
    color: #0B60A9;
    font-size: 12px;
  }

  .login-wrapper .register.forgot a{
    font-size: 10px;
  }

  .btn {
    color: #fff;
    display: block;
    padding: 10px;
    text-align: center;
    margin-bottom: 15px;
    border: none;
    width: 100%;
  }

  .login-form button:hover,
  .login-form button:active,
  .login-form button:active:focus{
    background-color: #08487e !important;
    border: none !important;
  }

  .btn-primary:not(:disabled):not(.disabled):active:focus,
  .btn-primary:not(:disabled):not(.disabled).active:focus,
  .show > .btn-primary.dropdown-toggle:focus{
    -webkit-box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
  }

</style>
